/*
  BRAND LEVEL THEME
  If a project has multiple sites that have shared theming, those styles can be placed here.
  If a site needs to override a brand level style, that can be done by writing the desired override in the site level theme.js file.
*/
export default {
  colors: {
    primary: '#005DAC',
    secondary: '#0E4275',
    tertiary: '#DE3700',
    dark: '#00223A',
    light: '#F8F8F9',
    warning: '#DE3700',
    error: '#DE3700',
    success: '#6CBF5A',
    background1: '#F8D7CC',
    background2: '#FEEFD0',
    background3: '#FFFCCC',
    background4: '#CCEAFE',
    background5: '#0096FA',
    background6: '#00223A',
    background7: '#F2F2F2',
  },
  alternateBgColor: false,
  backgroundColors: {
    tertiary: {
      textColor: 'white',
    },
  },
  fonts: {
    headings: {
      family: 'Source Sans Pro Bold, Helvetica, Sans Serif',
    },
    subheadings: {
      family: 'Source Sans Pro Bold, Helvetica, Sans Serif',
    },
    body: {
      family: 'Source Sans Pro, Helvetica, Sans Serif',
    },
  },
  components: {},
  shadow: '0 0.3rem 1rem 0 rgba(0, 0, 0, 0.1)',
  radius: '24px 4px 24px 4px',
}
